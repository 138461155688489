import { EventEmitter, Injectable } from "@angular/core";
import { MenuItemV1Model } from "../models/menu-v1.model";
import { NavUtilsV1 } from "../services/nav-utils-v1";
import { MenuConfiguracoes } from "../menus/configuracoes.menus";

@Injectable({
  providedIn: 'root'
})
export class NavItensConfiguracoesEvent {

  /**
   * Eventemitter publico
   */
  public eventMenuItensConfiguracoes = new EventEmitter<MenuItemV1Model[]>();

  public async menuConfiguracoesSolicitado() {

    let menuItensConfiguracoesTmp: MenuItemV1Model[] = [];
    let menuItensConfiguracoes: MenuItemV1Model[] = [];

    menuItensConfiguracoesTmp = MenuConfiguracoes;
    menuItensConfiguracoesTmp?.forEach(
      (element: MenuItemV1Model) => {
        element.config ? menuItensConfiguracoes.push(element) : null;
      }
    );

    NavUtilsV1.navItensMoment('navitensConfig');

    this.eventMenuItensConfiguracoes.emit(menuItensConfiguracoes);

  }
}
