import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { TratamentoErrosHttpErrorResponseService } from 'src/app/shared/services/tratamento-erros.service';

@Injectable()
export class TratamentoErroGlobal implements ErrorHandler {
  /**
   * Trata erros globais do aplicativo.
   *
   * Se o erro for um TypeError com uma mensagem que inclua
   * "Failed to fetch dynamically imported module", recarrega a
   * p gina. Caso contr rio, apenas exibe o erro no console.
   * @param error - O erro a ser tratado.
   */
  handleError(error: any): void {
    const recarregarPagina = () => {
      window.location.reload();
    }

    if (error instanceof TypeError && error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('Loading chunk')) {
      console.error('Erro detectado, recarregando a página...');
      recarregarPagina(); // Recarrega a página
    } else if (error.message.includes('ExpressionChangedAfterItHasBeenCheckedError')) {
      return;
    } else if (error instanceof HttpErrorResponse) {
      TratamentoErrosHttpErrorResponseService.tratarErro(error)
    } else {
      console.error('Erro não tratado:', error);
    }
  }
}