import { MenuItemV1Model } from "../models/menu-v1.model";

export const MenuGateway: MenuItemV1Model[] = [
  {
    gateway: true,
    isvertical: false,
    allowed: true,
    disabled: false,
    id: 1,   /** será montdo a númeração em tempo real de carregamento do banco de dados */
    label: 'Dashboard',
    icon: 'fas fa-home',
    link: '/dashboard',
  },
  {
    gateway: true,
    isvertical: false,
    allowed: true,
    disabled: false,
    id: 2,
    label: 'Relatório Reservas',
    icon: 'fas fa-id-card-alt',
    hsubitens: [
      {
        id: 3,
        parentId: 3,
        label: 'Online-v1',
        link: '/gateway/relatorio-reservas/online',
        icon: 'fas fa-user-edit me-1',
        allowed: true,
        disabled: undefined
      },
      {
        id: 4,
        parentId: 3,
        label: 'Online-v2',
        link: '/gateway/relatorio-reservas/v2/online',
        icon: 'fas fa-user-edit me-1',
        allowed: true,
        disabled: undefined
      },
    ]
  },
  {
    gateway: true,
    isvertical: false,
    allowed: true,
    disabled: false,
    id: 5,
    label: 'Movimentações',
    icon: 'fas fa-money-check-alt',
    hsubitens: [
      {
        id: 6,
        label: 'Movimentações Financeiras (v1)',
        link: '/gateway/movimentacoes/movimentacoes-financeiras',
        icon: 'fas fa-money-check-alt',
        parentId: 5,
        allowed: true,
        disabled: false,
      },
      {
        id: 7,
        label: 'Movimentações Financeiras (v2)',
        link: '/gateway/movimentacoes/v2/movimentacoes-financeiras',
        icon: 'fas fa-money-check-alt',
        parentId: 5,
        allowed: true,
        disabled: false,
      },
      {
        id: 8,
        label: 'Extrato Transferências',
        link: '/gateway/movimentacoes/extrato-transferencias',
        icon: 'dripicons-document',
        parentId: 5,
        allowed: true,
        disabled: false,
      },
    ],
  },
]
