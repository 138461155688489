import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResultV1Model } from 'src/app/core/models/result-v1.model';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})

export class TratamentoErrosHttpErrorResponseService {
  /**
   * Trata um erro HTTP e exibe um alerta com as mensagens de erro apropriadas.
   * 
   * @param err - O objeto de erro HttpErrorResponse.
   * 
   * Verifica se o erro é um erro de autenticação (401) ou de requisição inválida (400)
   * e exibe um alerta com as mensagens de erro retornadas no corpo da resposta.
   * Caso contrário, exibe um alerta com uma mensagem genérica de erro.
   * 
   * @see https://angular.io/api/common/http/HttpErrorResponse
   */
  public static tratarErro(err: HttpErrorResponse) {
    console.log('err')
    console.log(err)

    const erro: ResultV1Model = err.error;
    let mensagem = '';
    let titulo = '';

    if (typeof (erro.error) === 'string') {
      /**Caso tenha somente uma mensagem de erro */
      if(erro.message!){
        titulo = erro.error;
        mensagem = erro.message;
      }else{
        mensagem = erro.error;
      }
    } else if (Array.isArray(erro.error)) {
      /**Caso tenha um array com as mensagens de erro */
      mensagem = erro.error.map((e: { msg: string }) => e.msg).join('\n');
    } else {
      mensagem = 'Não foi possível concluir sua solicitação. Tente novamente mais tarde.'
    }

    if (err.status === 404) {
      /** Not Found */
      Swal.fire(
        'Erro na comunicação.',
        'Ocorreu um erro na tentativa de comunicar com o servidor. Rota para realizar a solicitação não localizada.',
        'error'
      );
    } else if (err.status === 500) {
      /** Internal Server Error */
      Swal.fire(
        'Erro no servidor.',
        'No momento estamos com uma inconsistência no servidor. Tente novamente mais tarde.',
        'error'
      );
    } else if (err.status === 400) {
      /** Bad Request */
      Swal.fire(
        erro.titulo, 
        mensagem, 
        'error'
      );
    } else if (err.status === 401) {
      /** Unauthorized */
      Swal.fire(
        'Acesso não autorizado!',
        'Ops, você não tem autorização para concluir esta solicitação.',
        'warning'
      );
    } else if (err.status === 504) {
      /** Gateway Time-out */
      Swal.fire(
        'Solicitação em andamento!',
        'Pedimos que aguarde. Este processo pode levar um tempo para ser finalizado.',
        'info'
      );
    } else if(mensagem!){
      Swal.fire(
        titulo! ? titulo : erro.titulo, 
        mensagem, 
        'error'
      );
    } else {
      Swal.fire(
        'Algo deu errado!',
        'Ops, algo deu errado durante a requisição. Tente novamente mais tarde.',
        'error'
      );
    }
  }
}