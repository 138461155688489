import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Page404Component } from './shared/page404/page404.component';
import { LayoutComponent } from './layouts/layout.component';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },

  /** AUTHENTICAÇÃO */
  {
    path: 'auth', loadChildren: () => import('./auth/auth.module')
      .then(m => m.AuthModule)
  },

  /** módulo COLABORADORES */
  {
    path: 'colaboradores', //
    component: LayoutComponent,
    loadChildren: () => import('./pages/colaboradores/colaborador.module')
      .then(m => m.ColaboradorModule),
  },

  /** módulo DASHBOARD */
  {
    path: 'dashboard',
    component: LayoutComponent,
    loadChildren: () => import('./pages/dashboard/dashboard.module')
      .then(m => m.DashboardModule),
  },

  /** módulo GATEWAY */
  {
    path: 'gateway',
    component: LayoutComponent,
    loadChildren: () => import('./pages/gateway/gateway.module')
      .then(m => m.GatewayModule),
  },

  /** RELATORIOS */
  {
    path: 'relatorios',
    // component: LayoutComponent,
    loadChildren: () => import('./pages/relatorios/relatorios.module')
      .then(m => m.RelatoriosModule),
  },

  // /** módulo RESERVAS */
  {
    path: 'reservas', //
    component: LayoutComponent,
    loadChildren: () => import('./pages/reservas/reservas.module')
      .then(m => m.ReservasModule),
  },
  /** shared components */
  { path: '**', component: Page404Component }

];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        scrollPositionRestoration: 'top'
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
