import { EventEmitter, Injectable } from "@angular/core";
import { MenuItemV1Model } from "../models/menu-v1.model";
import { MenuColaborador } from '../menus/colaboradores.menus';
import { NavUtilsV1 } from "../services/nav-utils-v1";
import { RulesColaboradorV1Model } from "src/app/pages/colaboradores/permissoes/model/rules-colaborador-v1.model";

@Injectable({
  providedIn: 'root'
})
export class NavItensColaboradoresEvent {

  /**
   * eventemitter publico
   */
  public eventMenuItensColaboradores = new EventEmitter<MenuItemV1Model[]>();

  /**
   * função pública de publicação dos itens de menu colaboradores
   */
  public async menucolaboradoresSolicitado(
    _rulesColaborador: RulesColaboradorV1Model
  ) {
    let menuItensColaboradores: MenuItemV1Model[] = [];

    menuItensColaboradores = MenuColaborador;
    menuItensColaboradores?.forEach(
      (element: MenuItemV1Model) => {
        if (element.colaborador) {
          /** pode acessar iten de menu: "Outros contas" */
          element.id == 2 ? ( // Contas Web
            element.allowed = _rulesColaborador.isContasWebMenu,
            _rulesColaborador.isContasWebMenu ? (
              element.hsubitens?.length != 0 ? (
                element.hsubitens?.forEach(
                  (subitens) => {
                    subitens.id == 3 ?  // Lista de contas web
                      subitens.allowed = _rulesColaborador.contasWebMenu.isListaContasWeb : null;
                    subitens.id == 4 ?  // Perfis permissões
                      subitens.allowed = _rulesColaborador.contasWebMenu.isPerfisPermissoes : null;
                    subitens.id == 5 ?  // Novo perfil
                      subitens.allowed = _rulesColaborador.contasWebMenu.isNovoPerfil : null;
                  }
                )
              ) : null
            ) : null
          ) : null;
        }
      }
    );

    NavUtilsV1.navItensMoment('navitensColaboradores');
    this.eventMenuItensColaboradores.emit(menuItensColaboradores);
  }
}
