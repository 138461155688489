<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a [routerLink]="[ '/dashboard' ]" class="logo logo-dark">
          <span class="logo-sm">
            <img src="https://hotel-ctc-files.s3.sa-east-1.amazonaws.com/imagens/sol-ctc.png" alt="Sol Hotel CTC"
              height="25">
          </span>
          <span class="logo-lg">
            <img src="https://hotel-ctc-files.s3.sa-east-1.amazonaws.com/imagens/lg-ctc.png" alt="Logo Hotel CTC"
              height="40">
          </span>
        </a>

        <a [routerLink]="[ '/dashboard' ]" class="logo logo-light">
          <span class="logo-sm">
            <img src="https://hotel-ctc-files.s3.sa-east-1.amazonaws.com/imagens/sol-ctc.png" alt="Sol Hotel CTC"
              height="25">
          </span>
          <span class="logo-lg">
            <img src="https://hotel-ctc-files.s3.sa-east-1.amazonaws.com/imagens/lg-ctc.png" alt="Logo Hotel CTC"
              height="40">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm p-0 font-size-16 vertinav-toggle header-item waves-effect"
        id="vertical-menu-btn" (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <button type="button" class="btn btn-sm p-0 font-size-16 horinav-toggle header-item waves-effect waves-light"
        data-bs-toggle="collapse" data-bs-target="#topnav-menu-content" (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- Perfil do Home Route -->
      <button type="button" class="btn btn-pad header-item noti-icon waves-effect me-0" 
        [routerLink]="[ '/dashboard' ]" routerLinkActive="active" placement="bottom" ngbTooltip="Home"
        (click)="carregarMenu()">
        <i class="fas fa-home"></i>
      </button>

      <!-- Perfil do Colaboradores -->
      <button type="button" [routerLink]="[ '/dashboard' ]" (click)="carregarMenu('colaboradores')"
        class="d-none d-lg-block btn btn-pad header-item noti-icon waves-effect me-0" routerLinkActive="active"
        placement="bottom" ngbTooltip="Colaboradores" *ngIf="this.userLogado?._rulesAccount!._rulesColaborador">
        <i class="mdi icon-size mdi-15px mdi-account-group-outline"></i>
      </button>

      <!-- Perfil do Reservas -->
      <button type="button" [routerLink]="[ '/dashboard' ]" (click)="carregarMenu('reservas')"
        class="d-none d-lg-block btn btn-pad header-item noti-icon waves-effect me-0" routerLinkActive="active"
        placement="bottom" ngbTooltip="Reservas" *ngIf="this.userLogado?._rulesAccount!._rulesReservas">
        <i class="far fa-calendar-check"></i>
      </button>

      <!--Perfil do Gateway-->
      <button type="button" [routerLink]="[ '/dashboard' ]" (click)="carregarMenu('financeiro')"
        class="d-none d-lg-block btn btn-pad header-item noti-icon waves-effect me-0" routerLinkActive="active"
        placement="bottom" ngbTooltip="Gateway">
        <i class="mdi icon-size mdi-15px mdi-cash-check"></i>
      </button>

    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block" ngbDropdown #drop="ngbDropdown">
        <button type="button" class="btn header-item waves-effect pe-0" ngbDropdownToggle id="page-header-user-dropdown"
          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (mouseenter)="openDropDownMenu(drop)">
          <img class="rounded-circle header-profile-user" src="{{userLogado?._avatar?.url}}" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{userLogado?.apelido}}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu (mouseleave)="closeDropDownMenu(drop)">
          <!-- item-->
          <h6 class="dropdown-header">{{ 'Bem vindo'}} <strong>{{ userLogado?.apelido}}</strong></h6>
          <a class="dropdown-item" [routerLink]="[ '/colaboradores/detalhes-do-perfil' ]"><i
              class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i> <span class="align-middle"
              key="t-profile">Meu perfil</span></a>
          <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"><i
              class="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i> <span class="align-middle"
              key="t-logout">Sair</span></a>
        </div>
      </div>

      <div class="dropdown d-lg-none d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect"
          (click)="toggleRightSidebar()">
          <i class="bx bx-cog bx-customize"></i>
        </button>
      </div>

    </div>
  </div>
</header>