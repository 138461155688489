import { MenuItemV1Model } from "../models/menu-v1.model";

export const MenuColaborador: MenuItemV1Model[] = [
  {
    colaborador: true,
    isvertical: false,
    allowed: true,
    disabled: undefined,
    id: 1,   /** será montdo a númeração em tempo real de carregamento do banco de dados */
    label: 'Colaboradores',
    icon: 'bx bxs-dashboard',
    link: '/colaboradores',
  },
  {
    colaborador: true,
    isvertical: false,
    allowed: undefined,
    disabled: undefined,
    id: 2,
    label: 'Contas Web',
    icon: 'fas fa-users-cog',
    hsubitens: [
      {
        id: 3,
        label: 'Lista de contas web',
        link: '/colaboradores/contas',
        icon: 'fas fa-users-cog',
        parentId: 2,
        allowed: undefined,
        disabled: undefined
      },
      {
        id: 4,
        label: 'Perfis permissões',
        link: '/colaboradores/permissoes',
        icon: 'fas fa-user-check',
        parentId: 2,
        allowed: undefined,
        disabled: undefined
      },
      {
        id: 5,
        label: 'Novo perfil',
        link: '/colaboradores/form-perfil-permissao',
        icon: 'fas fa-user-plus',
        parentId: 2,
        allowed: undefined,
        disabled: undefined
      }
    ]
  },
  {
    colaborador: true,
    isvertical: false,
    allowed: undefined,
    disabled: undefined,
    id: 6,
    label: 'Contas Agências',
    icon: 'fas fa-users-cog',
    hsubitens: [
      {
        id:7,
        label: 'Lista agências',
        link: '/colaboradores/contas',
        icon: 'fas fa-user-check',
        parentId: 6,
        allowed: undefined,
        disabled: undefined
      },
      {
        id: 8,
        label: 'Contas agências',
        link: '/colaboradores/contas',
        icon: 'fas fa-user-check',
        parentId: 6,
        allowed: undefined,
        disabled: undefined
      },
      {
        id: 9,
        label: 'Perfis permissões agências',
        link: '/colaboradores/permissoes',
        icon: 'fas fa-user-check',
        parentId: 6,
        allowed: undefined,
        disabled: undefined
      },
      {
        id: 10,
        label: 'Novo perfil agência',
        link: '/colaboradores/form-perfil-permissao',
        icon: 'fas fa-user-plus',
        parentId: 6,
        allowed: undefined,
        disabled: undefined
      }
    ]
  },
  {
    colaborador: true,
    isvertical: false,
    allowed: undefined,
    disabled: undefined,
    id: 11,
    label: 'Contas Parceiros',
    icon: 'fas fa-users-cog',
    hsubitens: [
      {
        id: 12,
        label: 'Lista parceiros',
        link: '/colaboradores/contas',
        icon: 'fas fa-user-check',
        parentId: 11,
        allowed: undefined,
        disabled: undefined
      },
      {
        id: 13,
        label: 'Proposta parceria',
        link: '/colaboradores/contas',
        icon: 'fas fa-user-check',
        parentId: 11,
        allowed: undefined,
        disabled: undefined
      },
      {
        id: 14,
        label: 'Nova parceria',
        link: '/colaboradores/contas',
        icon: 'fas fa-user-check',
        parentId: 11,
        allowed: undefined,
        disabled: undefined
      },
      {
        id: 15,
        label: 'Contas parceiros',
        link: '/colaboradores/contas',
        icon: 'fas fa-user-check',
        parentId: 11,
        allowed: undefined,
        disabled: undefined
      },
      {
        id: 16,
        label: 'Perfis permissões parceiros',
        link: '/colaboradores/permissoes',
        icon: 'fas fa-user-check',
        parentId: 11,
        allowed: undefined,
        disabled: undefined
      },
      {
        id: 17,
        label: 'Novo perfil parceiros',
        link: '/colaboradores/form-perfil-permissao',
        icon: 'fas fa-user-plus',
        parentId: 11,
        allowed: undefined,
        disabled: undefined
      }
    ]
  },
  // {
  //   colaborador: true,
  //   isvertical: false,
  //   allowed: undefined,
  //   disabled: undefined,
  //   id: 7,
  //   label: 'Permissões',
  //   icon: 'fas fa-briefcase',
  //   hsubitens: [
  //     {
  //       id: 8,
  //       label: 'Perfis permissões',
  //       link: '/colaboradores/permissoes',
  //       icon: 'fas fa-user-check',
  //       parentId: 7,
  //       allowed: undefined,
  //       disabled: undefined
  //     },
  //     {
  //       id: 9,
  //       label: 'Novo perfil',
  //       link: '/colaboradores/form-perfil-permissao',
  //       icon: 'fas fa-user-plus',
  //       parentId: 7,
  //       allowed: undefined,
  //       disabled: undefined
  //     }
  //   ]
  // }
]
