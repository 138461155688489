import { EventEmitter, Injectable } from "@angular/core";
import { MenuItemV1Model } from "../models/menu-v1.model";
import { NavUtilsV1 } from "../services/nav-utils-v1";
import { MenuGateway } from "../menus/gateway.menus";
import { RulesGatewayV1Model } from "src/app/pages/colaboradores/permissoes/model/rules-gateway-v1.model";

@Injectable({
  providedIn: 'root'
})
export class NavItensGatewayEvent {

  /**
   * Eventemitter publico
   */
  public eventMenuItensGateway = new EventEmitter<MenuItemV1Model[]>();

  public async menuGatewaySolicitado(_rulesGateway: RulesGatewayV1Model) {

    let menuItensGateway: MenuItemV1Model[] = [];

    menuItensGateway = MenuGateway;
    menuItensGateway?.forEach((element: MenuItemV1Model) => {
      if(element.gateway){
        /**Pode acessar item menu: 'Cobranças'*/
        element.id == 2 // Cobranças
        ? ((element.allowed = _rulesGateway.isMenuCobrancas),
          _rulesGateway.isMenuCobrancas ? (
            element.hsubitens?.length! > 0 ? 
            (element.hsubitens?.forEach((subitem) => {
                subitem.id == 3 ? //Item do Menu Cobranças -> Online
                (subitem.allowed = _rulesGateway.menuCobrancas.isSubMenuOnline) : null;
              }) 
            ) : null
          ) : null
        ) : null;

        /**Pode acessar item menu: Movimentações */
        element.id == 6 ? ((element.allowed = _rulesGateway.isMenuMovimentacoes),
        _rulesGateway.isMenuMovimentacoes ? (
            element.hsubitens?.length! > 0 ? 
            (element.hsubitens?.forEach((subitem) => {
              subitem.id == 7 ? //Item do menu Movimentações -> Movimentações Financeiras
              (subitem.allowed = _rulesGateway.menuMovimentacoes.isSubMenuMovimentacoesFinanceiras) : null;
              subitem.id == 8 ? //Item do menu Movimentações -> Extrato Transferências
              (subitem.allowed = _rulesGateway.menuMovimentacoes.isSubMenuExtratoTransfer) : null;
            })) : null
          ) : null
        ) : null

        /**Pode acessar item menu: Gestão Conta Asaas */
        // element.id == 9 ? ((element.allowed = _rulesGateway.isMenuGestaoContaAsaas),
        // _rulesGateway.isMenuGestaoContaAsaas ? (
        //     element.hsubitens?.length! > 0 ? 
        //     (element.hsubitens?.forEach((subitem) => {
        //       subitem.id == 10 ? //Item do menu Gestão Conta Asaas -> Dashboard
        //       (subitem.allowed = _rulesGateway.menuGestaoContaAsaas.isSubMenuDashboard) : null;
        //     })) : null
        //   ) : null
        // ) : null
      }
    });

    NavUtilsV1.navItensMoment('navitensGateway');

    this.eventMenuItensGateway.emit(menuItensGateway);

  }
}
