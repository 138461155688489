import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityUtil } from 'src/app/core/utils/security.util';
import { HeaderUserService } from 'src/app/core/services/headeruser.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { UserAuthV1Model } from 'src/app/auth/models/user-auth-v1.model';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { NavItensColaboradoresEvent } from '../navegation/events/nav-itens-colaboradores.event';
import { NavUtilsV1 } from '../navegation/services/nav-utils-v1';
import { NavItensGatewayEvent } from '../navegation/events/nav-itens-gateway.event';
import { NavItensReservasEvent } from '../navegation/events/nav-itens-reservas.event';
import { NavItensDashBoardEvent } from '../navegation/events/nav-itens-dashboard.event';
import { AuthGuard } from 'src/app/core/guards/auth.guard';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  userLogado!: UserAuthV1Model | null;
  accessItensDashBoard?: any;
  constructor(
    private route: Router,
    private loaderService: LoaderService,
    private headerService: HeaderUserService,
    private readonly navItensColaboradores: NavItensColaboradoresEvent,
    private readonly navItensFinanceiro: NavItensGatewayEvent,
    private readonly navItensReservas: NavItensReservasEvent,
    private readonly navItensDashBoardEvent: NavItensDashBoardEvent,
    private authGuard: AuthGuard
  ) {
    /**
     * Coleta dados do usuário authenticado
     */
    try {
      this.userLogado = SecurityUtil.getAccount();
    } catch (error) {
      this.authGuard.canActivate();
    }
    this.accessItensDashBoard = this.userLogado?._rulesAccount._rulesColaborador;
    /**
     * atualiza os dados da conta quando o perfil e atualizado
     */
    this.headerService.eventoAlteraDados
      .subscribe(x => {
        this.getUseLogado();
      });
  }

  getUseLogado() {
    this.userLogado = SecurityUtil.getAccount();
  }

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() menuResponsivoButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit(): void {
    /**
     * Paraliza o processo de loader
     */
    this.loaderService.stopLoader();
  }

  openDropDownMenu(dropdownMenu: NgbDropdown) {
    dropdownMenu.open();
  }
  closeDropDownMenu(dropdownMenu: NgbDropdown) {
    dropdownMenu.close();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    SecurityUtil.clear();
    sessionStorage.clear();
    this.route.navigate([`/auth/login`])
  }

  carregarMenu(menuASerCarregado?: 'colaboradores' | 'financeiro' | 'reservas') {
    switch (menuASerCarregado) {
      case 'colaboradores':
        this.navItensColaboradores.menucolaboradoresSolicitado(
          NavUtilsV1.getNavItensRulescolaboradores()
        );
        break;
      case 'financeiro':
        this.navItensFinanceiro.menuGatewaySolicitado(
          NavUtilsV1.getNavItensRulesGateway()
        );
        break;
      case 'reservas':
        this.navItensReservas.menuReservasSolicitado(
          NavUtilsV1.getNavItensRulesReservas()
        )
        break;
      default:
        this.navItensDashBoardEvent.menuDashBoardSolicitado();
    }
  }
}
