// Components do sistema
import { HttpHeaders } from '@angular/common/http';
// Model
import { UserAuthV1Model } from 'src/app/auth/models/user-auth-v1.model';
import { accountaccess } from 'src/app/core/api';

export class SecurityUtil {
  public static update = null;

  public static set(
    account: UserAuthV1Model,
    token: string,
  ) {
    localStorage.setItem('ctc-account', btoa(JSON.stringify(account)));
    localStorage.setItem('ctc-token', token);
  }

  public static setAccount(
    account: UserAuthV1Model
  ) {
    localStorage.setItem('ctc-account', btoa(JSON.stringify(account)));
  }

  public static setToken(token: string) {
    localStorage.setItem('ctc-token', token);
  }

  public static getAccount(): UserAuthV1Model | null {
    const data = JSON.parse(atob(localStorage.getItem('ctc-account') as string));
    const account: UserAuthV1Model = {
      apelido: data.apelido,
      _idAccount: data._idAccount,
      nome: data.nome,
      telefone: data.telefone,
      email: data.email,
      _avatar: data._avatar,
      _rulesAccount: data._rulesAccount,
      lock: data.lock,
      rulesUser: data.rulesUser
    };
    if (data) {
      return account;
    } else {
      return null;
    }
  }

  public static getToken(): string | null {
    const data = localStorage.getItem('ctc-token');
    if (data) {
      return data;
    } else {
      return null;
    }
  }

  public static hasToken(): boolean {
    if (this.getToken())
      return true;
    else
      return false;
  }

  public composeHeader() {
    const token = localStorage.getItem('ctc-token');
    const headers = new HttpHeaders().set('Authorization', `bearer ${token}`);
    return headers;
  }

  
  public composeHeaderAsaas() {
    const headers = new HttpHeaders().set('accountaccess', accountaccess);
    return headers;
  }

  public static clear() {
    localStorage.removeItem('navMoment');
    localStorage.removeItem('ctc-token');
    localStorage.removeItem('ctc-perfil');
    localStorage.removeItem('ctc-account');
    localStorage.removeItem('navitensPortal');
    localStorage.removeItem('navitensGateway');
    localStorage.removeItem('navitensReservas');
    localStorage.removeItem('navitensAgencias');
    localStorage.removeItem('navitensParceiros');
    localStorage.removeItem('navitensColaboradores');
  }
}
