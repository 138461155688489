import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ModalModule } from 'ngx-bootstrap/modal';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale);
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { NgbModule, NgbTooltipModule, NgbPopoverModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutsModule } from './layouts/layouts.module';
import { AuthGuard } from './core/guards/auth.guard';
import { SecurityUtil } from './core/utils/security.util';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask, provideNgxMask } from 'ngx-mask';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TratamentoErroGlobal } from './core/services/tratamento-erro-global-v1.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right'
    }),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgbNavModule,
    LayoutsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    BsDropdownModule.forRoot(),
  ],
  exports:[
  ],
  providers: [
    AuthGuard,
    SecurityUtil,
    provideAnimations(),
    provideEnvironmentNgxMask(),
    provideNgxMask(),
    {
      provide: ErrorHandler,
      useClass: TratamentoErroGlobal
    }
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {
}
