import { EventEmitter, Injectable } from "@angular/core";
import { MenuItemV1Model } from "../models/menu-v1.model";
import { NavUtilsV1 } from "../services/nav-utils-v1";
import { NavItensColaboradoresEvent } from "./nav-itens-colaboradores.event";
import { NavItensReservasEvent } from "./nav-itens-reservas.event";
import { NavItensGatewayEvent } from "./nav-itens-gateway.event";
import { NavItensDashBoardEvent } from "./nav-itens-dashboard.event";

@Injectable({
  providedIn: 'root'
})
export class NavItensMomentEvent {
  constructor(
    private readonly navItensReservas: NavItensReservasEvent,
    private readonly navItensColaboradores: NavItensColaboradoresEvent,
    private readonly navItensGateway: NavItensGatewayEvent,
    private itensNavDashboadEvent: NavItensDashBoardEvent
  ) { }

  /**
   * eventemitter publico
   */
  public eventMenuItensMoment = new EventEmitter<MenuItemV1Model[]>();

  /**
   * função pública de publicação dos itens de menu colaboradores
   */
  public async menuItensMomento(navItens: string) {
    switch (navItens) {

      case "navitensColaboradores":
        this.navItensColaboradores
          .menucolaboradoresSolicitado(
            NavUtilsV1.getNavItensRulescolaboradores()
          );
        NavUtilsV1.navItensMoment('navitensColaboradores');
        break;

      case "navitensReservas":
        this.navItensReservas
          .menuReservasSolicitado(
            NavUtilsV1.getNavItensRulesReservas()
          );
        NavUtilsV1.navItensMoment('navitensReservas');
        break;

      case "navitensGateway":
        this.navItensGateway
          .menuGatewaySolicitado(NavUtilsV1.getNavItensRulesGateway());
        NavUtilsV1.navItensMoment('navitensGateway');
        break;

      default:
        this.itensNavDashboadEvent.menuDashBoardSolicitado();
        break;
    }
  }
}
