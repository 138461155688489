import { EventEmitter, Injectable } from "@angular/core";
import { MenuHospedes } from "../menus/manager-hospedes.menus";
import { MenuItemV1Model } from "../models/menu-v1.model";

@Injectable({
  providedIn: 'root'
})
export class NavItensHospedesEvent {

  /**
   * eventemitter publico
   */
  public eventMenuItensHospedes = new EventEmitter<MenuItemV1Model[]>();

  /**
   * função pública de publicação dos itens de menu BackGround
   */
  public async menuMngHospedesSolicitado() {

    let menuItensHorizontalHospedesTmp: MenuItemV1Model[] = [];
    let menuItensHorizontalHospedes: MenuItemV1Model[] = [];
    menuItensHorizontalHospedesTmp = MenuHospedes;
    menuItensHorizontalHospedesTmp?.forEach(
      (element: MenuItemV1Model) => {
        element.hospedes ? menuItensHorizontalHospedes.push(element) : null;
      }
    );

    this.eventMenuItensHospedes.emit(menuItensHorizontalHospedes);
  }
}
